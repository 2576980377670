.item-preview {
  #audio-indicator {
    display: flex;
    position: absolute;
    color: #e32d19;
    opacity: 0.5;
    z-index: 25;
    padding: 20px;
  }
}
