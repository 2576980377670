.previewThumbnail {
  .selectionButton {
    display: none;
  }
  &:hover .selectionButton {
    display: block;

    &:hover {
      svg > path {
        stroke: #0f87ff;
      }
      svg > path + path {
        fill: #0f87ff;
      }
    }
  }
}
