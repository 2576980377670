.widescreen-toggle {
  box-sizing: border-box;
  border-radius: 16px 0px 0px 16px;
}
.vr-toggle {
  box-sizing: border-box;
  border-radius: 0px 16px 16px 0px;
}
.viewer .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
